import { Link } from "gatsby" 
import React, {useContext, useEffect, useRef, useState} from "react"
import Layout from "../layout";
import { Helmet } from "react-helmet";
import {AddToCart} from '../cart/miniCart'  
import ProductsNew from "../goods/ProductsNew";
import PageContext from "../../context/PageContext";
import { GOOD_DETAIL } from "../../query/goods";
import { BsFolder2 } from "react-icons/bs";
import { useQuery } from "@apollo/client";
import { ChevronRightIcon } from "@heroicons/react/solid";
import {default as Garance } from "../../icons/garance.svg";
import {default as Zaruka } from "../../icons/zaruka.svg";



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
   
  export const ProductDetail = ({ pageContext, location }) => {
      
    const [context, dispatch] = useContext(PageContext);
    const searchString = context?.searchString;

    const [selected, setSelected] = useState(0);
    const [item, setItem] = useState(pageContext);
    const scroller = useRef();
    const visibleArea = useRef();
 

    let sections = item?.description?.split("<section>");
    let description = [];
    let fraze = '';
    if(sections && sections.length > 0) {
    
        let sectionParts = sections[0].split("<");
        sectionParts = sectionParts[0].split(" ");
        sectionParts.forEach((part, partIdx) => {
            fraze = fraze + part 
            if(partIdx === 1) {
            return "";
            } else {
            fraze = fraze + " ";
            }
        });
        sections.forEach((section) => {
            const subSections = section.split("<subsection>");
            if(subSections && subSections.length > 0) {
            const desc = {
                title: subSections[0],
                content: subSections[1],
            }
            description.push(desc)
            }

        })
    } 

    const {data} = useQuery(GOOD_DETAIL, {
        variables: {
            id: item.id
        }
    });

    useEffect(() => {
        if(data) {
            setItem({
                ...item,
                quantity: data.goodFindOne.quantity,
                priceVat: data.goodFindOne.priceVat,
            })
        }  
    //eslint-disable-next-line 
    }, [data]);
 

    let sale = false
    if(item.supRrp > 0 && item.priceVat < item.supRrp) {
        sale = Math.floor(100-((item.priceVat*100)/item.supRrp));
    }

    const product = {
        name: item.name,
        price: parseInt(item.priceVat).toLocaleString() + ' Kč',
        rating: 4,
        images: 
        item.files.map(file => ( 
                {
                name:item.name,
                src:file.filename,
                alt: item.name
                }
            ))
        , 
        description: item.description,
        table: [
            { name: "Dostupnost", value: item.quantity > 0 ? <span className="font-bold text-green-600">skladem</span> : item.note },
            { name: "Výrobce", value: <div>Plyšové hračky <Link to={'/plysaci/?v=' + encodeURIComponent(item.producer).replace(/%20/g, "+")} className="text-amber-600 underline hover:no-underline">{item.producer}</Link></div> },
            { name: "Rozměr", value: item.sizeNote ? item.sizeNote + " cm" : item.size + " cm" },
        ],  
        details: [
            { name: "Výrobce", value: <div><Link to={'/plysaci/?v=' + encodeURIComponent(item.producer).replace(/%20/g, "+")} className="text-amber-600 underline hover:no-underline">{item.producer}</Link> / {item.manItem}</div> },
            { name: "Rozměr", value: item.sizeNote ? item.sizeNote + " cm" : item.size + " cm" },
            { name: "Kód zboží", value: item.code },
            { name: "EAN", value: item.ean },
            { name: "Čárový kód", value: item.upcA },
        ],  
    }
    item.quantity > 0 && product.table.push({ name: "Expedujeme", value: 'dnes nebo následující pracovní den' })

    useEffect(() => {
          let breadCrumb = context.breadCrumbPath
            if(breadCrumb && breadCrumb.length === 0) {
                //neni nastavena, nastavime prvni cat
                if(item?.categories[0]) {
                let path = item.categories[0]?.path;
                // path.push({
                //   name: item.name,
                //   url: 'detail/' + item.alias,
                //   type: 'goods_detail'
                // }) 
                dispatch({
                    type: 'SET_BREADCRUMB',
                    payload: {
                        breadCrumbPath: path
                    }
                })
              }
            }  
            
        const width = visibleArea?.current?.clientWidth;  
        function updateScrollPosition() { 
            const fromLeft = scroller.current.scrollLeft

            let visibleImg = 0;
            if(fromLeft > 0) {
                visibleImg = Math.round(fromLeft/width)
            } 
            setSelected(visibleImg)

        }

        if (scroller && scroller.current) {
            scroller.current.addEventListener("scroll", updateScrollPosition, false);
            return function cleanup() {
                //eslint-disable-next-line 
                scroller?.current?.removeEventListener("scroll", updateScrollPosition, false);
            };
        }
    //eslint-disable-next-line 
    }, []);


const itemRefs = useRef(new Map());

const executeScroll = (imageIdx) => {  
  //setSelected(imageIdx) 
  itemRefs.current.get(imageIdx).scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' })    
 }

 const title = item.name + " " + item.rod + " " + (item.quantity > 0 ? "skladem" : "") + (sale ? "/ " + sale + "% sleva, plyšák nyní za " + item.priceVat + " Kč" : "");

 
 
return (

  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title> 
    </Helmet>
    <Layout>
       {searchString && <><ProductsNew location={location} /></>} 
      
      {!searchString &&
      <>
      <div className="bg-white">
        <div className="max-w-2xl mx-auto py-4  px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
            

          <div className="flex flex-col-reverse" ref={visibleArea}>
          {item.priceVat > 0 && 
            <div className="  mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none" >
                <div className="grid grid-cols-4 lg:grid-cols-5 gap-2 relative">
                  {product.images.map((image,imageIdx) => (
                    <div role="button" tabIndex={0} onClick={() => executeScroll(imageIdx)} onKeyDown={() => executeScroll(imageIdx)} key={imageIdx} className="relative h-24 bg-white rounded-md flex items-center   justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer   hover:bg-gray-50 focus:outline-none">
                      
                          <span className="sr-only">{image?.name}</span>
                          <span className="absolute inset-0 rounded-md overflow-hidden">
                            <img src={image?.src} alt="" className="w-full h-full object-center object-cover " />
                          </span>
                          <span
                            className={classNames(
                              selected === imageIdx ? 'ring-amber-500' : 'ring-transparent',
                              'absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none border transition-all ease-in-out duration-300 '
                            )}
                            aria-hidden="true"
                          />  
                    </div>
                  ))}
                </div>
              </div>
            }

            <div className="w-full flex overflow-x-auto snap-x snap-mandatory no-scrollbar rounded-md shadow-md " ref={scroller}>
                {product.images.map((image,imageIdx) => (
                    <div key={imageIdx} ref={e => itemRefs.current.set(imageIdx, e)} className={"w-full flex-shrink-0  snap-center relative " + (item.priceVat === 0 && "opacity-70" )}>
                      <img
                        src={image?.src}
                        aria-hidden alt={image?.alt}
                        className="w-full h-auto border rounded-md"
                      />
                      {item.priceVat === 0 && <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full text-center flex items-center justify-center">
                        <span className="p-5 bg-black bg-opacity-50 text-white font-bold w-full">Prodej ukončen</span></div>
                       }
                    </div>
                  ))}
              </div>
            </div>


            {/* Product info */}
            <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
              <div className="flex justify-between space-x-2">
                <h1 className="text-3xl font-extrabold tracking-tight text-gray-600">{product.name} <span className="font-normal">{item.size && '(' + item.size + ' cm)'.replace(/ /g, "\u00a0")}</span></h1>
                
              </div>
              {item.priceVat > 0 && 
              <div className="mt-6 grid space-y-8 sm:space-y-0 sm:grid-cols-2 p-3 bg-gray-100">
                <div>
                  <div className="grid sm:flex sm:space-x-4 items-center text-2xl">
                    <p className=" text-red-600 font-bold">{product.price}</p>
                    {sale && <p className="line-through text-gray-500 text-lg">{item.supRrp.toLocaleString()} Kč</p>}
                  </div>
                  <div className="mt-1"> 
                    {item.quantity > 0 ? <div className=" text-green-600 font-bold">{item.quantity===1 && "Poslední kus"} skladem</div> : <div className="text-gray-600">Dostupné {item.note}</div>}
                  </div>
                </div>
                <div className="flex items-center  text-center">
                <AddToCart item={item} />

                </div>
              </div>
                }
                  {item.quantity > 0 && <div className="p-1 text-xs w-full text-right text-gray-600">odesíláme obratem</div>}
              
              <div className="mt-6"> 

              <div className="grid grid-cols-1 lg:grid-cols-5">
                <table className="min-w-full divide-y divide-gray-200  border col-span-4">
                  <tbody>
                  {item.priceVat > 0 && 
                    <tr className="bg-gray-50">
                      <td className="px-3 py-2 whitespace-nowrap text-sm font-medium text-gray-900">Cena s DPH</td>
                      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{product.price}</td>
                    </tr>
                    }
                    {product.table.map((row, rowIdx) => (
                      <tr key={rowIdx} className={rowIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="px-3 py-2 whitespace-nowrap text-sm font-medium text-gray-900">{row.name}</td>
                        <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{row.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {item.priceVat > 0 && 
                <div className="grid grid-cols-2 lg:grid-cols-1 border-t-0 lg:border-t lg:border-l-0 lg:grid  justify-end lg:justify-center lg:w-auto  text-gray-600 bg-gray-50 border">
                
                  <div className="grid  items-center justify-center p-2 w-full">
                    <Garance className="h-20 mx-auto" />
                    <span className="text-xs">30 dní na vrácení</span>
                  </div>
                  <div className="grid items-center justify-center p-2 w-full">
                    <Zaruka className=" h-20 mx-auto" />
                    <span className="text-xs">záruka 3 roky</span>
                  </div>
                </div>  
                }
              </div>
                
            
              
              </div>


              <div>
                  <div className=" text-gray-700 space-y-6  mt-10">
                    {description.length > 0 && 
                      description.map((section, sectionIdx) => (
                        <div key={sectionIdx} >
                          <div className="text-lg font-bold">
                            <Link to={'/vyhledavani/?s=' + encodeURIComponent(section.title).replace(/%20/g, "+")} className="mb-1 text-amber-600 underline hover:no-underline">{section.title}</Link>
                          </div>
                          {section.content !== '<p>~</p>' && <div className="prose prose-sm max-w-max"  dangerouslySetInnerHTML={{ __html: section.content }} />}
                        </div>
                      ))
                    }
                    <div className="prose prose-sm max-w-max"  dangerouslySetInnerHTML={{ __html: item.descriptionExt }} />
                  </div>
              </div>

            </div>
          </div> 
          <div className="grid lg:grid-cols-2 bg-gray-100 p-4 mt-10">
            <div >

             <div className=" p-4">
                <div className="my-2 text-lg font-bold text-gray-500"> 
                  Plyšák <Link to={'/plysaci/detail/' + item.alias + '/'} className=" text-amber-600 underline hover:text-amber-800 hover:no-underline">
                      {item.name}
                  </Link> je s dalšími plyš hračkami v kategoriích:
                </div>
                <div className="text-sm mt-3">
                  {item.categories.map((cat,catIdx) => (
                    <div key={catIdx} className=" flex space-x-2 items-center mb-1">
                      <span className="inline-flex space-x-2 items-center" >
                        <div className="flex items-center space-x-2 text-gray-500  ">
                          <span className="block sm:hidden">Plyšáci</span>
                          <span className="hidden sm:block">Plyšové hračky</span>
                          
                        </div>
                          <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-300" />
                      </span>
                      {cat.path.map((pathItem, pathItemIdx) => (
                        <span className="inline-flex space-x-2 items-center" key={pathItemIdx} >
                          {pathItemIdx > 0 &&
                            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-300" />
                          
                          }
                        <Link to={'/plysaci/' + pathItem.url + '/'} className="flex items-center space-x-2 text-amber-600 underline hover:text-amber-800 hover:no-underline">
                          <BsFolder2 className="w-4 h-4 opacity-70 " /><span className="truncate">{pathItem.name}</span>
                        </Link>
                        </span>
                      ))}
                    </div>
                  ))}
                  </div>
              </div> 

            </div>
            {item.priceVat > 0 && 
            <div className="-order-1 lg:order-2">
              <div > 
                <div className="p-4">
                  <div className="my-2 text-lg font-bold text-gray-500">Podrobnosti o plyšové hračce</div> 
                      <table className="min-w-full divide-y divide-gray-200 my-3 border">
                        <tbody> 
                          {product.details.map((row, rowIdx) => (
                            <tr key={rowIdx} className={rowIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                              <td className="px-3 py-2 whitespace-nowrap text-sm font-medium text-gray-900">{row.name}</td>
                              <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{row.value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table> 
                    </div>
                </div> 
                <div className="mt-6 grid space-y-8 sm:space-y-0 sm:grid-cols-2 p-3 bg-gray-100">
                <div>
                  <div className="grid sm:flex sm:space-x-4 items-center text-2xl">
                    <p className=" text-red-600 font-bold">{product.price}</p>
                    {sale && <p className="line-through text-gray-500 text-lg">{item.supRrp.toLocaleString()} Kč</p>}
                  </div>
                  <div className="mt-1"> 
                    {item.quantity > 0 ? <div className=" text-green-600 font-bold">{item.quantity===1 && "Poslední kus"} skladem</div> : <div className="text-gray-600">Dostupné {item.note}</div>}
                  </div>
                </div>
                <div className="flex items-center  text-center">
                  <AddToCart item={item} />

                </div>
              </div>
                  {item.quantity > 0 && <div className="p-1 text-xs w-full text-right text-gray-600">odesíláme obratem</div>}
            </div>
            }
          </div> 
          <div>

            <div className="p-4 mt-10">
                <div className="my-2 text-lg font-bold text-gray-500">Obecné informace k produktu</div>
                <div className="text-gray-600 text-sm prose prose-amber max-w-full">
                <p>Všechny snímky produktu <Link to={'/plysaci/detail/' + item.alias + '/'} className=" text-amber-600 underline hover:no-underline">
                      {item.name}
                </Link> mají ilustrační charakter: odchylky v barevnosti mezi snímkem a fyzickým zbožím jsou možné. Hračky jsou měkké, vycpané, a tak se na tlapkách neudrží a stát často nebudou (není-li uvedeno v popisku jinak). 
                Zobrazené fotografie od výrobce <Link to={'/plysaci/?v=' + encodeURIComponent(item.producer).replace(/%20/g, "+")} className="text-amber-600 underline hover:no-underline">{item.producer}</Link> mohou být markýrované profesionálním fotografem, např. může být plyšák pro potřeby pořízení snímku podepřen, aby seděl nebo stál.
                </p>
                <p>
                  Podívejte se na podobná plyšová zvířátka od <Link to={'/plysaci/?v=' + encodeURIComponent(item.producer).replace(/%20/g, "+")} className="text-amber-600 underline hover:no-underline">{item.producer}</Link> a plyšové hračky 
                  jako <Link to={'/vyhledavani/?s=' + encodeURIComponent(fraze).replace(/%20/g, "+")} className="text-amber-600 underline hover:no-underline">{fraze}</Link>.
                  </p>
                </div>
            </div>
          </div>
        </div>
      </div>
      </>}

      
       
    </Layout>
  </>
)
}